import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { ToastrModule } from 'ngx-toastr';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { CloudMessagingService } from './shared.service';
import { FitConfigProviderService } from './fit-config-provider.service';
import { UnauthorizedRequestInterceptor } from './unauthorized-request.interceptor';
import { NotificationsService } from './notifications.service';
import { AuthorizationNeededGuard } from './guards/authorization-needed.guard';
import { DateFormatPipe, DATE_FORMAT_PIPE_FORMATS, DateFormatSettings } from './pipes/date-format.pipe';
import { DateAgoPipe } from './pipes/date-ago.pipe';
import { MonthsAndDaysPipe } from '@app/pipes/months-and-days.pipe';
import { SecondsToTimestampPipe } from '@app/pipes/seconds-to-timestamp.pipe';
import { CounterComponent } from './components/counter/counter.component';
import { DocumentationLinkComponent } from './components/documentation-link/documentation-link.component';
import { LibraryModalComponent } from './components/library-modal/library-modal.component';
import { LibraryModalService } from './components/library-modal/library-modal.service';
import { AssignModalComponent } from './components/assign-modal/assign-modal.component';
import { AssignModalService } from './components/assign-modal/assign-modal.service';
import { AreaMarkerComponent } from './components/area-marker/area-marker.component';
import { RequiredDirective } from './directives/required.directive';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { StepperFixDirective } from '@app/iphone-fix-directives/iPhone-stepper-fix-directive';
import { VideoJsComponent } from './components/video-js/video-js.component';
import { ListSortComponent } from './components/list-sort/list-sort.component';
import { DropdownSortComponent } from './components/dropdown-sort/dropdown-sort.component';
import { TimeZoneService } from './timezone.service';
import { OnboardingFixDirective } from './directives/onboarding.directive';
import { EmailNamePipe } from './pipes/email-name.pipe';
import { TrimDeletedEmail } from './pipes/delete-email.pipe';
import { DecomposeObjectPipe } from './pipes/decompose-object.pipe';

const dateFormatsFactory = (config: FitConfigProviderService): DateFormatSettings => {
    return {
        dateFormat: config.dateFormat,
        dateTimeFormat: config.dateTimeFormat,
        timeFormat: config.timeFormat
    };
};

@NgModule({
    declarations: [
        DateFormatPipe,
        StepperFixDirective,
        AssignModalComponent,
        CounterComponent,
        DocumentationLinkComponent,
        LibraryModalComponent,
        RequiredDirective,
        OnboardingFixDirective,
        AreaMarkerComponent,
        DateAgoPipe,
        MonthsAndDaysPipe,
        SecondsToTimestampPipe,
        TrimDeletedEmail,
        VideoJsComponent,
        ListSortComponent,
        DropdownSortComponent,
        EmailNamePipe,
        DecomposeObjectPipe
    ],
    
    imports: [
        CommonModule,
        FormsModule,
        NgSelectModule,
        PaginationModule.forRoot(),
        ToastrModule.forRoot({
            iconClasses: {
                error: 'toast-custom-error',
                info: 'toast-custom-info',
                success: 'toast-custom-success',
                warning: 'toast-custom-warning'
            }
        }),
        ButtonsModule.forRoot()
    ],
    
    exports: [
        AssignModalComponent,
        CounterComponent,
        DocumentationLinkComponent,
        LibraryModalComponent,
        AreaMarkerComponent,
        StepperFixDirective,
        RequiredDirective,
        OnboardingFixDirective,
        VideoJsComponent,
        DateFormatPipe,
        DateAgoPipe,
        MonthsAndDaysPipe,
        SecondsToTimestampPipe,
        EmailNamePipe,
        ListSortComponent,
        DropdownSortComponent,
        TrimDeletedEmail,
        DecomposeObjectPipe
    ],
    
    providers: [
        AuthorizationNeededGuard,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: UnauthorizedRequestInterceptor,
            multi: true
        },
        NotificationsService,
        TimeZoneService,
        AssignModalService,
        LibraryModalService,
        CloudMessagingService,
        { provide: DATE_FORMAT_PIPE_FORMATS, useFactory: dateFormatsFactory, deps: [FitConfigProviderService] },
    ]
})

export class SharedModule { }
