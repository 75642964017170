import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthorizationNeededGuard } from '@shared/guards/authorization-needed.guard';
import { HasPinGuard } from '@shared/guards/has-pin.guard';
import { ProfileRootGuard } from '@shared/guards/profile-root-guard';
import { MainCmsGuard } from '@shared/guards/root-guard';
import { isSuspendedGuard } from '@shared/guards/isSuspended.guard';
import { SchedulerEnabledGuard } from '@shared/guards/scheduler-enabled.guard';
import { MessagingEnabledGuard } from '@shared/guards/messaging-enabled.guard';
import { GroupChatEnabledGuard } from '@shared/guards/group-chat-enabled.guard';
import { CoachingEnabledGuard } from '@shared/guards/coaching-enabled.guard';
import { TeamsEnabledGuard } from '@shared/guards/teams-enabled.guard';
import { GiftCardsEnabledGuard } from '@shared/guards/gift-cards-enabled.guard';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        loadChildren: () => import('./home/home.module').then(module => module.HomeModule),
        canActivate: [AuthorizationNeededGuard]
    },
    {
        path: 'meal',
        loadChildren: () => import('./meal/meal.module').then(m => m.MealModule),
        canActivate: [AuthorizationNeededGuard, isSuspendedGuard]
    },
    {
        path: 'meal-group',
        loadChildren: () => import('./meal-group/meal-group.module').then(m => m.MealGroupModule),
        canActivate: [AuthorizationNeededGuard, isSuspendedGuard]
    },
    {
        path: 'exercise',
        loadChildren: () => import('./exercise/exercise.module').then(module => module.ExerciseModule),
        canActivate: [AuthorizationNeededGuard, isSuspendedGuard],
    },
    {
        path: 'nutrition-program',
        loadChildren: () => import('./nutrition-program/nutrition-program.module').then(m => m.NutritionProgramModule),
        canActivate: [AuthorizationNeededGuard, isSuspendedGuard]
    },
    {
        path: 'nutrition-program-tailor-made',
        loadChildren: () => import('./nutrition-program-tailor-made/nutrition-program-tailor-made.module')
            .then(m => m.NutritionProgramTailorMadeModule),
        canActivate: [AuthorizationNeededGuard, isSuspendedGuard],
        data: { tailorMade: true }
    },
    {
        path: 'training',
        loadChildren: () => import('./training/training.module').then(module => module.TrainingModule),
        canActivate: [AuthorizationNeededGuard, isSuspendedGuard]
    },
    {
        path: 'program',
        loadChildren: () => import('./program/program.module').then(m => m.ProgramModule),
        canActivate: [AuthorizationNeededGuard, isSuspendedGuard]
    },
    {
        path: 'tailor-made',
        loadChildren: () => import('./tailor-made/tailor-made.module').then(m => m.TailorMadeModule),
        canActivate: [AuthorizationNeededGuard, isSuspendedGuard]
    },

    {
        path: 'goal',
        loadChildren: () => import('./goal/goal.module').then(module => module.GoalModule),
        canActivate: [AuthorizationNeededGuard, isSuspendedGuard]
    },
    {
        path: 'report-page',
        loadChildren: () => import('./report-page/report-page.module').then(module => module.ReportPageModule),
        canActivate: [AuthorizationNeededGuard]
    },
    {
        path: 'subscription',
        loadChildren: () => import('./subscription/subscription.module').then(module => module.SubscriptionModule),
        canActivate: [AuthorizationNeededGuard, isSuspendedGuard]
    },
    {
        path: 'payment',
        loadChildren: () => import('./payment/payment.module').then(module => module.PaymentModule),
        canActivate: [AuthorizationNeededGuard, isSuspendedGuard]
    },
    {
        path: 'charges',
        loadChildren: () => import('./charge/charge.module').then(module => module.ChargeModule),
        canActivate: [AuthorizationNeededGuard, isSuspendedGuard]
    },
    {
        path: 'blog',
        loadChildren: () => import('./blog/blog.module').then(module => module.BlogModule),
        canActivate: [AuthorizationNeededGuard, isSuspendedGuard]
    },
    {
        path: 'muscle-group',
        loadChildren: () => import('./muscle-group/muscle-group.module').then(module => module.MuscleGroupModule),
        canActivate: [AuthorizationNeededGuard, isSuspendedGuard]
    },
    {
        path: 'food-preference',
        loadChildren: () => import('./food-preference/food-preference.module').then(module => module.FoodPreferenceModule),
        canActivate: [AuthorizationNeededGuard, isSuspendedGuard]
    },
    {
        path: 'diet',
        loadChildren: () => import('./diet/diet.module').then(module => module.DietModule),
        canActivate: [AuthorizationNeededGuard, isSuspendedGuard]
    },
    // {
    //     path: 'program-type',
    //     loadChildren: () => import('./program-type/program-type.module').then(module => module.ProgramTypeModule),
    //     canActivate: [AuthorizationNeededGuard]
    // },
    {
        path: 'allergen',
        loadChildren: () => import('./allergen/allergen.module').then(module => module.AllergenModule),
        canActivate: [AuthorizationNeededGuard, isSuspendedGuard]
    },
    // {
    //     path: 'intensity',
    //     loadChildren: () => import('./intensity/intensity.module').then(module => module.IntensityModule),
    //     canActivate: [AuthorizationNeededGuard]
    // },
    {
        path: 'training-type',
        loadChildren: () => import('./training-type/training-type.module').then(module => module.TrainingTypeModule),
        canActivate: [AuthorizationNeededGuard, isSuspendedGuard]
    },
    {
        path: 'environment',
        loadChildren: () => import('./environment/environment.module').then(module => module.EnvironmentModule),
        canActivate: [AuthorizationNeededGuard, isSuspendedGuard]
    },
    {
        path: 'level',
        loadChildren: () => import('./level/level.module').then(module => module.LevelModule),
        canActivate: [AuthorizationNeededGuard, isSuspendedGuard]
    },
    {
        path: 'review',
        loadChildren: () => import('./review/review.module').then(module => module.ReviewModule),
        canActivate: [AuthorizationNeededGuard, isSuspendedGuard]
    },
    {
        path: 'user',
        loadChildren: () => import('./user/user.module').then(module => module.UserModule),
        canActivate: [AuthorizationNeededGuard, isSuspendedGuard]
    },
    // {
    //     path: 'user-status',
    //     loadChildren: () => import('./user-status/user-status.module').then(module => module.UserStatusModule),
    //     canActivate: [AuthorizationNeededGuard, isSuspendedGuard]
    // },
    {
        path: 'applications',
        loadChildren: () => import('./applications/applications.module').then(module => module.ApplicationsModule),
        canActivate: [AuthorizationNeededGuard, isSuspendedGuard]
    },
    {
        path: 'app-insight',
        loadChildren: () => import('./app-insight/app-insight.module').then(module => module.AppInsightModule),
        canActivate: [AuthorizationNeededGuard, MainCmsGuard]
    },
    {
        path: 'live-events',
        loadChildren: () => import('./live-events/live-events.module').then(module => module.LiveEventsModule),
        canActivate: [AuthorizationNeededGuard, isSuspendedGuard]
    },
    {
        path: 'equipments',
        loadChildren: () => import('./equipment/equipment.module').then(module => module.EquipmentModule),
        canActivate: [AuthorizationNeededGuard, isSuspendedGuard]
    },
    {
        path: 'feed',
        loadChildren: () => import('./feed/feed.module').then(module => module.FeedModule),
        canActivate: [AuthorizationNeededGuard, isSuspendedGuard]
    },
    {
        path: 'translate',
        loadChildren: () => import('./translate/translate.module').then(module => module.TranslateModule),
        canActivate: [AuthorizationNeededGuard, isSuspendedGuard]
    },
    {
        path: 'mobile-config',
        loadChildren: () => import('./mobile-config/mobile-config.module').then(module => module.MobileConfigModule),
        canActivate: [AuthorizationNeededGuard, HasPinGuard, isSuspendedGuard]
    },
    {
        path: 'achievements',
        loadChildren: () => import('./achievements/achievements.module').then(module => module.AchievementsModule),
        canActivate: [AuthorizationNeededGuard, isSuspendedGuard]
    },
    {
        path: 'coach-packages',
        loadChildren: () => import('./coach-packages/coach-packages.module').then(module => module.CoachPackagesModule),
        canActivate: [AuthorizationNeededGuard, isSuspendedGuard]
    },
    {
        path: 'landing-page',
        loadChildren: () => import('./landing-page/landing-page.module').then(module => module.LandingPageModule),
        canActivate: [AuthorizationNeededGuard, isSuspendedGuard]
    },
    {
        path: 'packages',
        loadChildren: () => import('./packages/packages.module').then(module => module.PackagesModule),
        canActivate: [AuthorizationNeededGuard, isSuspendedGuard]
    },
    {
        path: 'package-tiers',
        loadChildren: () => import('./package-tiers/package-tiers.module').then(module => module.PackageTiersModule),
        canActivate: [AuthorizationNeededGuard, isSuspendedGuard]
    },
    {
        path: 'exercise-library',
        loadChildren: () => import('./libraries/exercise/exercise.module').then(module => module.ExerciseLibraryModule),
        canActivate: [AuthorizationNeededGuard, isSuspendedGuard]
    },
    {
        path: 'plan-requests',
        loadChildren: () => import('./program-requests/program-requests.module').then(module => module.ProgramRequestsModule),
        canActivate: [AuthorizationNeededGuard, isSuspendedGuard]
    },
    {
        path: 'recording',
        loadChildren: () => import('./recording/recording.module').then(module => module.RecordingModule),
        canActivate: [AuthorizationNeededGuard]
    },
    {
        path: 'resolution-center',
        loadChildren: () => import('./resolution-center-coach/resolution-center-coach.module')
            .then(module => module.ResolutionCenterCoachModule),
        canActivate: [AuthorizationNeededGuard]
    },
    {
        path: 'insight',
        loadChildren: () => import('./insights/insights.module').then(module => module.InsightsModule),
        canActivate: [AuthorizationNeededGuard, isSuspendedGuard]
    },
    {
        path: 'affiliate-partner',
        loadChildren: () => import('./affiliate-partners/affiliate-partners.module').then(module => module.AffiliatePartnersModule),
        canActivate: [AuthorizationNeededGuard, isSuspendedGuard]
    },
    {
        path: 'referral-code',
        loadChildren: () => import('./referral-codes/referral-codes.module').then(module => module.ReferralCodesModule),
        canActivate: [AuthorizationNeededGuard, isSuspendedGuard]
    },
    {
        path: 'referral-partner',
        loadChildren: () => import('./referral-partners/referral-partners.module').then(module => module.ReferralPartnersModule),
        canActivate: [AuthorizationNeededGuard, isSuspendedGuard]
    },
    {
        path: 'challenge',
        loadChildren: () => import('./challenge/challenge.module').then(module => module.ChallengeModule),
        canActivate: [AuthorizationNeededGuard, isSuspendedGuard]
    },
    {
        path: 'lesson',
        loadChildren: () => import('./lesson/lesson.module').then(module => module.LessonModule),
        canActivate: [AuthorizationNeededGuard, isSuspendedGuard, CoachingEnabledGuard]
    },
    {
        path: 'course',
        loadChildren: () => import('./course/course.module').then(module => module.CourseModule),
        canActivate: [AuthorizationNeededGuard, isSuspendedGuard, CoachingEnabledGuard]
    },
    {
        path: 'lesson-type',
        loadChildren: () => import('./lesson-type/lesson-type.module').then(module => module.LessonTypeModule),
        canActivate: [AuthorizationNeededGuard, isSuspendedGuard, CoachingEnabledGuard]
    },
    {
        path: 'lesson-goal',
        loadChildren: () => import('./lesson-goal/lesson-goal.module').then(module => module.LessonGoalModule),
        canActivate: [AuthorizationNeededGuard, isSuspendedGuard, CoachingEnabledGuard]
    },
    {
        path: 'lesson-achievement',
        loadChildren: () => import('./lesson-achievement/lesson-achievement.module').then(module => module.LessonAchievementModule),
        canActivate: [AuthorizationNeededGuard, isSuspendedGuard, CoachingEnabledGuard]
    },
    {
        path: 'course-category',
        loadChildren: () => import('./course-category/course-category.module').then(module => module.CourseCategoryModule),
        canActivate: [AuthorizationNeededGuard, isSuspendedGuard, CoachingEnabledGuard]
    },
    {
        path: 'team',
        loadChildren: () => import('./team/team.module').then(module => module.TeamModule),
        canActivate: [AuthorizationNeededGuard, isSuspendedGuard, TeamsEnabledGuard]
    },
    {
        path: 'gift-card',
        loadChildren: () => import('./gift-card/gift-card.module').then(module => module.GiftCardModule),
        canActivate: [AuthorizationNeededGuard, isSuspendedGuard, GiftCardsEnabledGuard]
    },
    {
        path: 'profile',
        loadChildren: () => import('./profile/profile.module').then(module => module.ProfileModule),
        canActivate: [AuthorizationNeededGuard, ProfileRootGuard]
    },
    {
        path: 'preview',
        loadChildren: () => import('./shared/components/import-library/import-library.module').then(module => module.ImportLibraryModule),
        canActivate: [AuthorizationNeededGuard, isSuspendedGuard]
    },
    {
        path: 'statistics',
        loadChildren: () => import('./app-analytics/app-analytics.module')
            .then(module => module.AppAnalyticsModule),
        canActivate: [AuthorizationNeededGuard, isSuspendedGuard]
    },
    {
        path: 'reports',
        loadChildren: () => import('./app-analytics/app-analytics.module')
            .then(module => module.AppAnalyticsModule),
        canActivate: [AuthorizationNeededGuard, isSuspendedGuard]
    },
    {
        path: 'business-intelligence',
        loadChildren: () => import('./business-intelligence/business-intelligence.module')
            .then(module => module.BusinessIntelligenceModule),
        canActivate: [AuthorizationNeededGuard, isSuspendedGuard]
    },
    {
        path: 'help',
        loadChildren: () => import('./help/help.module').then(module => module.HelpModule),
        canActivate: [AuthorizationNeededGuard]
    },
    {
        path: 'user-search',
        loadChildren: () => import('./user-search/user-search.module').then(module => module.UserSearchModule),
        canActivate: [AuthorizationNeededGuard, isSuspendedGuard]
    },
    {
        path: 'scheduler',
        loadChildren: () => import('./scheduler/scheduler.module').then(module => module.SchedulerModule),
        canActivate: [AuthorizationNeededGuard, isSuspendedGuard, SchedulerEnabledGuard]
    },
    // {
    //     path: 'app-preview',
    //     loadChildren: () => import('./modulator/modulator.module').then(module => module.ModulatorModule),
    //     canActivate: [AuthorizationNeededGuard, ModulatorGuard]
    // },
    {
        path: 'questionnaire',
        loadChildren: () => import('./questionnaire/questionnaire.module').then(module => module.QuestionnaireModule),
        canActivate: [AuthorizationNeededGuard, isSuspendedGuard]
    },
    {
        path: 'finished-questionnaires',
        loadChildren: () => import('./questionnaire-finished/questionnaire-finished.module').then(module => module.QuestionnaireFinishedModule),
        canActivate: [AuthorizationNeededGuard, isSuspendedGuard]
    },
    {
        path: 'client-messages',
        loadChildren: () => import('./client-messages/client-messages.module').then(module => module.ClientMessagesModule),
        canActivate: [AuthorizationNeededGuard, isSuspendedGuard, MessagingEnabledGuard]
    },
    {
        path: 'client-messages/:id',
        loadChildren: () => import('./client-messages/client-messages.module').then(module => module.ClientMessagesModule),
        canActivate: [AuthorizationNeededGuard, isSuspendedGuard, MessagingEnabledGuard]
    },
    {
        path: 'reported-messages',
        loadChildren: () => import('./reported-messages/reported-messages.module').then(module => module.ReportedMessagesModule),
        canActivate: [AuthorizationNeededGuard, isSuspendedGuard, GroupChatEnabledGuard]
    },
    {
        path: 'promo-codes',
        loadChildren: () => import('./promo-codes/promo-codes.module').then(module => module.PromoCodesModule),
        canActivate: [AuthorizationNeededGuard, isSuspendedGuard]
    },

    {
        path: 'auth',
        loadChildren: () => import('./auth/auth.module').then(module => module.AuthModule)
    },
    { path: '**', redirectTo: '/' }
];
@NgModule({
    imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
