<div class="sort-wrapper">
    <ng-select name="order"
               class="select-input dropdown-ordering"
               [items]="orderingOptions"
               [searchable]="false"
               [clearable]="false"
               bindLabel="name"
               bindValue="value"
               [(ngModel)]="currentOrder"
               (ngModelChange)="changeOrder()">
    </ng-select>

    <ng-select name="filter"
               class="select-input dropdown-ordering"
               [items]="filterOptions"
               [searchable]="false"
               [clearable]="false"
               bindLabel="name"
               bindValue="value"
               [(ngModel)]="currentFilter"
               (ngModelChange)="changeFilter()">
    </ng-select>
</div>
