import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject } from 'rxjs';
import { FitConfigProviderService } from './fit-config-provider.service';
import { FitApiClientService } from './fit-api-client.service';
import { FitAppConfig } from './fit-app-config';
import { ResolutionCenterService } from '@app/resolution-center/resolution-center.service';

interface CoachPackage {
    id: number;
    name: string;
    price: number;
    clients_limit?: string;
    promotional_price?: string;
    billing_period: string;
    include_custom_application: boolean;
    pwa_wizard_advanced_flow: boolean;
    cancel_auto_renewal: boolean;
    account_status: string;
}

export interface User {
    avatar_image_url: string;
    application_name: string;
    email: string;
    id: number;
    first_name: string;
    last_name: string;
    coach_add_card_payment_gateway: string;
    flow: Flow;
    android_app_link: string;
    coach_package: CoachPackage;
    ios_app_link: string;
    profile_progress: number;
    wizard_section_status: WizardSectionStatus;
    roles: string[];
    release_date: string;
    free_trial_ends_at: string;
    used_modulator: boolean;
    custom_status_mobile_apps: string;
    messaging_enabled: boolean;
    group_chat_enabled: boolean;
    scheduling_enabled: boolean;
    referrals_enabled: boolean;
    challenges_enabled: boolean;
    coaching_enabled: boolean;
    teams_enabled: boolean;
    gift_cards_enabled: boolean;
    user_health_tracking_enabled: boolean;
    //status_mobile_apps: 'STATUS_INSUFFICIENT_DATA' | 'STATUS_DATA_IN_REVIEW' | 'STATUS_IN_DEVELOPMENT' | 'STATUS_IN_PRODUCTION' | 'STATUS_SUSPENDED' | 'STATUS_APP_IN_REVIEW' | 'STATUS_CANCELED' | 'STATUS_EOL';
    status_mobile_apps: string;
    account_status: 'ACCOUNT_STATUS_FREE_TRIAL_NOT_STARTED' | 'ACCOUNT_STATUS_FREE_TRIAL' | 'ACCOUNT_STATUS_REGULAR';
    unpaid_invoices_present: boolean;
    timezone: string;
    pwa_app_link: string;
    next_billing_date: string;
    up_to_clients: UpToclients;
}

interface UpToclients{
    numberOfUsers: string;
    label: string;
    price: string;
}

interface WizardSectionStatus {
    app_info: boolean;
    app_visuals: boolean;
    app_visuals_2: boolean;
    billing_info: boolean;
    your_info: boolean;
}

export declare type Flow = 'FLOW_NORMAL' | 'FLOW_PROFILE_MANDATORY_NOT_COMPLETED' | 'FLOW_PROFILE_OPTIONAL_NOT_COMPLETED';

interface TokenResponse {
    token_type: string;
    expires_in: number;
    access_token: string;
    refresh_token: string;
}

export function tokenClear() {
    return localStorage.removeItem('access_token');
}

export function tokenGetter() {
    return localStorage.getItem('access_token');
}

export function tokenSetter(access_token: string) {
    return localStorage.setItem('access_token', access_token);
}

export function refreshTokenClear() {
    return localStorage.removeItem('refresh_token');
}

export function refreshTokenGetter() {
    return localStorage.getItem('refresh_token');
}

export function refreshTokenSetter(refresh_token: string) {
    return localStorage.setItem('refresh_token', refresh_token);
}

@Injectable({ providedIn: 'root' })

export class AuthorizationService {
    private static MAX_TIMEOUT_VALUE = 2147483647;
    constructor(
        private fitApi: FitApiClientService,
        private jwtHelper: JwtHelperService,
        private resolutionService: ResolutionCenterService,
        private router: Router,
        private fitConfig: FitConfigProviderService
    ) {
        this.onServiceInit();
    }

    get showSpinner() { return this._showSpinner; }
    set showSpinner(value) { this._showSpinner = value; }

    get user() { return this._user; }
    get root() { return this._root; }

    public fetching = false;

    public async login(username: string, password: string) {
        const grant_type = 'password';
        const client_id = this._fitConfig.client_id;
        const client_secret = this._fitConfig.client_secret;

        const { data, error } = await this.fitApi.post<TokenResponse>(
            '/oauth/token',
            { grant_type, client_id, client_secret, username, password }
        );
        
        if (!error) {
            this.afterSuccessfulLogin(data);
        }

        return { data, error };
    }

    public async loginWithOtpCode(otpCode: string) {
        const config = await this.fitConfig.getConfig();
        const grant_type = 'otp_grant';
        const client_id = config.client_id;
        const client_secret = config.client_secret;

        const { data, error } = await this.fitApi.post<TokenResponse>(
            '/oauth/token',
            { grant_type, client_id, client_secret, username: otpCode }
        );
        
        if (!error) {
            this.afterSuccessfulLogin(data);
        }

        return { data, error };
    }

    public async logout() {
        if (this.isAuthenticated()) {
            await this.fitApi.logout();
        }
        
        this.clearToken();
        this._user.next(null);
        this.resolutionService.clearIntervalUnreadMessages();
        this.router.navigateByUrl('/auth/login');
    }

    public requestOneTimePassword(destination: string) {
        return this.fitApi.post<null>('/forgotten-password/send-otp', {destination});
    }

    public changePassword(request: {
        destination: string;
        code: string;
        password: string;
    }) {
        return this.fitApi.post<null>('/forgotten-password/change-password', request);
    }

    public async refreshUserData() {
        await this.fetchUser();
    }

    public async refreshToken() {
        const refresh_token = refreshTokenGetter();
        if (!refresh_token) {
            this.showSpinner = false;
            return false;
        }

        const rnd = Math.random() * 1000;

        const grant_type = 'refresh_token';
        const client_id = this._fitConfig.client_id;
        const client_secret = this._fitConfig.client_secret;

        if (!localStorage.getItem('refresh_token_settled')) {
            localStorage.setItem('refresh_token_settled', rnd.toString());
            const { data, error } = await this.fitApi.post<TokenResponse>(
                '/oauth/token',
                { grant_type, client_id, client_secret, refresh_token }
            );
            
            if (!error) {
                this.setToken(data.access_token);
                this.setRefreshToken(data.refresh_token);
                this.initRefreshTokenTimer();
                this.showSpinner = false;
                return true;
            } else {
                localStorage.removeItem('refresh_token_settled');
                await this.logout();
                this.showSpinner = false;
                return false;
            }
        } else {
            this.showSpinner = false;
        }
    }

    public isAuthenticated(): boolean {
        return !this.jwtHelper.isTokenExpired();
    }

    public clearToken() {
        tokenClear();
        refreshTokenClear();
    }

    public checkFetchingUser() {
        return this.fetching;
    }

    private afterSuccessfulLogin(data: TokenResponse) {
        this.setToken(data.access_token);
        this.setRefreshToken(data.refresh_token);
        this.initRefreshTokenTimer();
        this.fetchUser();
    }

    private setToken(access_token: string) {
        tokenSetter(access_token);
    }

    private setRefreshToken(refresh_token: string) {
        refreshTokenSetter(refresh_token);
    }

    private initRefreshTokenTimer() {
        if (!this.isAuthenticated()) {
            return;
        }

        const expiresAt = this.jwtHelper.getTokenExpirationDate();

        let runIn = expiresAt.getTime() - Date.now() - 180 * 1000;
        if (runIn < 1) {
            this.refreshToken();
            return;
        }

        if (runIn > AuthorizationService.MAX_TIMEOUT_VALUE) {
            runIn = AuthorizationService.MAX_TIMEOUT_VALUE;
        }

        this.refreshTokenTimer = setTimeout(() => {
            this.refreshToken();
        }, runIn);
        
        localStorage.removeItem('refresh_token_settled');
        this.showSpinner = false;
    }

    private clearRefreshTokenTimer() {
        clearTimeout(this.refreshTokenTimer);
    }

    private async fetchUser() {
        this.fetching = true;
        const { data, error } = await this.fitApi.get<User>('/admin/user');
        
        if (!error) {
            if (data.avatar_image_url) {
                if (data.avatar_image_url.includes('lorem')) {
                    data.avatar_image_url = '';
                }
            }
            
            this.fitConfig.enableUserHealthTracking = data.user_health_tracking_enabled;
            this.fitConfig.enableClientMessaging = data.messaging_enabled;
            this.fitConfig.enableGroupChat = data.group_chat_enabled;
            this.fitConfig.enableScheduler = data.scheduling_enabled;
            this.fitConfig.enableReferrals = data.referrals_enabled;
            this.fitConfig.enableChallenges = data.challenges_enabled;
            this.fitConfig.enableCoaching = data.coaching_enabled;
            this.fitConfig.enableTeams = data.teams_enabled;
            this.fitConfig.enableGiftCards = data.gift_cards_enabled;
            this.fitConfig.isRoot = data.roles.some((role) => role === 'root');
            this.fitConfig.isAdministrator = data.roles.some((role) => role === 'administrator');
            this.fitConfig.isOwner = data.roles.some((role) => role === 'owner');
            this.fitConfig.isCoach = data.roles.some((role) => role === 'coach');
            this._root = this.fitConfig.isRoot;
            // data.coach_package.include_custom_application = true;
            // data.wizard_section_status.app_visuals_2 = false;
            // data.coach_package.pwa_wizard_advanced_flow = true;
            // data.status_mobile_apps = 'STATUS_CANCELED';
            this._user.next(data);
        }
        
        this.showSpinner = false;
    }

    private async onServiceInit() {
        this.showSpinner = true;
        const config = await this.fitConfig.getConfig();
        this._fitConfig = config;
        const isAuthenticated = this.isAuthenticated();
        
        if (isAuthenticated) {
            this.initRefreshTokenTimer();
            this.fetchUser();
        } else {
            const success = await this.refreshToken();
            if (success) {
                this.router.navigateByUrl('/');
            }
        }
    }

    private refreshTokenTimer;
    private _root = false;
    private _showSpinner = false;
    private _user = new BehaviorSubject<User>(null);
    private _fitConfig: FitAppConfig;
}
