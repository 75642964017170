import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export type OrderBy = 'newest' | 'oldest' | 'name' | 'name_desc';
export type FilterBy = 'all' | 'leanon' | 'youtube';

export interface OrderOption {
    name: string;
    value: OrderBy;
}

export interface FilterOption {
    name: string;
    value: FilterBy;
}

@Component({
    selector: 'dropdown-sort',
    templateUrl: './dropdown-sort.component.html',
    styleUrls: ['./dropdown-sort.component.scss']
})

export class DropdownSortComponent implements OnInit {
    constructor() {}

    @Input() fromGallery = false;
    @Input() public currentOrder: OrderBy = 'name';
    @Input() public currentFilter: FilterBy = 'all';
    @Output() public onChangeOrder = new EventEmitter<OrderBy>();
    @Output() public onFilter = new EventEmitter<FilterBy>();

    public orderingOptions: OrderOption[] = [
        { name: 'Newest first', value: 'newest' },
        { name: 'Oldest first', value: 'oldest' },
        { name: 'Name (A-Z)', value: 'name' },
        { name: 'Name (Z-A)', value: 'name_desc' },
    ];

    public filterOptions: FilterOption[] = [
        { name: 'All videos', value: 'all' },
        { name: 'Custom only', value: 'leanon' },
        { name: 'YouTube only', value: 'youtube' },
    ];

    public ngOnInit() {
        if (sessionStorage.getItem('order-by') && this.fromGallery) {
            this.currentOrder = sessionStorage.getItem('order-by') as OrderBy;
            this.onChangeOrder.emit(this.currentOrder);
        }

        if (sessionStorage.getItem('filter-by') && this.fromGallery) {
            this.currentFilter = sessionStorage.getItem('filter-by') as FilterBy;
            this.onFilter.emit(this.currentFilter);
        }
    }

    public changeOrder() {
        sessionStorage.setItem('order-by', this.currentOrder)
        this.onChangeOrder.emit(this.currentOrder)
    }

    public changeFilter() {
        sessionStorage.setItem('filter-by', this.currentFilter)
        this.onFilter.emit(this.currentFilter)
    }
}
