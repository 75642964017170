import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { FitAppConfig } from './fit-app-config';

export interface SidebarNavigationItem {
    url?: string;
    title?: string;
    homeTitle?: string;
    subtitle?: string;
    description?: string;
    color?: string;
    icon?: string;
    iconHome?: string;
    opened?: boolean;
    hideOnHome?: boolean;
    items?: SidebarNavigationItem[];
}

export interface SidebarNavigation {
    title: string;
    subtitle?: string;
    color: string;
    icon: string;
    iconHome: string;
    opened: boolean;
    url?: string;
    items?: SidebarNavigationItem[];
}

export interface Country {
    value: string;
    group: string;
    text: string;
}

@Injectable({ providedIn: 'root' })

export class FitConfigProviderService {
    public static settings: FitAppConfig;

    get navigation() {
        return this._navigation;
    }

    get apiUrl() {
        return FitConfigProviderService.settings.apiUrl;
    }
    
    get dateFormat() {
        return FitConfigProviderService.settings.format.date;
    }
    
    get timeFormat() {
        return FitConfigProviderService.settings.format.time;
    }
    
    get dateTimeFormat() {
        return FitConfigProviderService.settings.format.dateTime;
    }

    get pinApp() {
        return this._pinApp;
    }
    
    set pinApp(value) {
        this._pinApp = value;
    }

    set enableUserHealthTracking(value) {
        this._enableUserHealthTracking = value;
    }

    get enableUserHealthTracking() {
        return this._enableUserHealthTracking;
    }

    set enableClientMessaging(value) {
        this._enableClientMessaging = value;
    }

    get enableClientMessaging() {
        return this._enableClientMessaging;
    }

    set enableGroupChat(value) {
        this._enableGroupChat = value;
    }

    get enableGroupChat() {
        return this._enableGroupChat;
    }

    set enableScheduler(value) {
        this._enableScheduler = value;
    }

    get enableScheduler() {
        return this._enableScheduler;
    }

    set enableReferrals(value) {
        this._enableReferrals = value;
    }

    get enableReferrals() {
        return this._enableReferrals;
    }

    set enableChallenges(value) {
        this._enableChallenges = value;
    }

    get enableChallenges() {
        return this._enableChallenges;
    }

    set enableCoaching(value) {
        this._enableCoaching = value;
    }

    get enableCoaching() {
        return this._enableCoaching;
    }

    set enableTeams(value) {
        this._enableTeams = value;
    }

    get enableTeams() {
        return this._enableTeams;
    }

    set enableGiftCards(value) {
        this._enableGiftCards = value;
    }

    get enableGiftCards() {
        return this._enableGiftCards;
    }

    set enableAppTryout(value) {
        this._enableAppTryout = value;
    }

    get enableAppTryout() {
        return this._enableAppTryout;
    }

    get isAdministrator() {
        return this._isAdministrator;
    }

    set isAdministrator(value) {
        this._isAdministrator = value;
    }

    get isCoach() {
        return this._isCoach;
    }

    set isCoach(value) {
        this._isCoach = value;
        if (!this.isAdministrator && this._isCoach) {
            //this._navigation = this.enableScheduler ? this.getCoachNavigation() : [];
            this._navigation = this.getCoachNavigation();
            
            if (this.enableTeams) {
               /*
                const index = this._navigation[0].items.indexOf(this.navigation[0].items.find(item => item.title === 'All users'))
                this._navigation[0].items.splice(index, 0, {
                    title: 'Teams',
                    url: '/team',
                });
                */
                this._navigation[0].items.push({
                    title: 'Teams',
                    url: '/team',
                });
            }

            if (this.enableScheduler) {
                this._navigation.push({
                    title: 'Scheduler',
                    color: '#C2F1FF',
                    icon: 'assets/icons/ic_website.svg',
                    iconHome: 'assets/icons/ic_website_home.svg',
                    opened: false,
                    items: [
                        {
                            title: 'Calendar',
                            url: '/scheduler/calendar',
                        },
                        {
                            title: 'Calendar settings',
                            url: '/scheduler/calendar-settings',
                        },
                        {
                            title: 'Event types',
                            url: '/scheduler/event-types',
                        },
                    ],
                },);
            }
        }
    }

    get isOwner() {
        return this._isOwner;
    }

    set isOwner(value) {
        this._isOwner = value;
    }

    set isRoot(value) {
        this._isRoot = value;
        const appPin = localStorage.getItem('pin_application');
        this._navigation = appPin ? this.getAdminNavigation() : this.getMainCmsNavigation();
        
        if (this._isRoot) {
            if (appPin) {
                //extends admin navigation
                let myClientsIndex = appPin ? 3 : 0;
                let appAnalyticsIndex = appPin ? 4 : 1;

                this._navigation[myClientsIndex].items.push({
                    title: 'Packages',
                    url: '/packages',
                });

                this._navigation[myClientsIndex].items.push({
                    title: 'Package tiers',
                    url: '/package-tiers',
                });

                //root can see whole app analytics
                
                this._navigation[appAnalyticsIndex].items.push({
                    title: 'Statistics',
                    url: '/statistics',
                });

                this._navigation[appAnalyticsIndex].items.push({
                    title: 'Reports',
                    url: '/statistics/reports',
                });

                this._navigation[appAnalyticsIndex].items.push({
                    title: 'Content analytics',
                    url: '/business-intelligence',
                });
            }
        } else {
            if (this.enableScheduler && (this._isOwner || this._isCoach)) {
                const index = this._navigation[2].items.indexOf(this.navigation[2].items.find(item => item.title === 'Live events'))
                this._navigation[2].items.splice(index, 0, {
                    title: 'Scheduler',
                    color: '#0B2441',
                    icon: 'assets/icons/circle_section_promotion_cms.svg',
                    iconHome: 'assets/icons/circle_section_promotion_cms.svg',
                    opened: false,
                    items: [
                        {
                            title: 'Calendar',
                            url: '/scheduler/calendar',
                        },
                        {
                            title: 'Calendar settings',
                            url: '/scheduler/calendar-settings',
                        },
                        {
                            title: 'Event types',
                            url: '/scheduler/event-types',
                        },
                    ],
                });
            }

            if (appPin) {
                if (appPin == '4o2e34' || appPin == '79891' || appPin == '8a2a3' || appPin == '651b9') {
                    //we are displaying whole app analytics to Mariah, Golf STRONG, SW7 and Zania
                    this._navigation[4].items.push({
                        title: 'Statistics',
                        url: '/statistics',
                    });
        
                    this._navigation[4].items.push({
                        title: 'Reports',
                        url: '/statistics/reports',
                    });
        
                    this._navigation[4].items.push({
                        title: 'Content analytics',
                        url: '/business-intelligence',
                    });
                } else {
                    //others can see only content analytics
                    this._navigation[4].items.push({
                        title: 'Content analytics',
                        url: '/business-intelligence',
                    });
                }
            }
        }

        if (appPin) {
            if (this.enableTeams) {
                const index = this._navigation[3].items.indexOf(this.navigation[3].items.find(item => item.title === 'Comments'))
                this._navigation[3].items.splice(index, 0, {
                    title: 'Teams',
                    url: '/team',
                });
            }

            if (this.enableGiftCards) {
                this._navigation[5].items.push({
                    title: 'Gift cards',
                    url: '/gift-card',
                });
            }

            if (this.enableAppTryout) {
                this._navigation[5].items.push({
                    title: 'App tryout',
                    url: '/app-tryout',
                });
            }
            
            if (this.enableClientMessaging) {
                const index = this._navigation[3].items.indexOf(this.navigation[3].items.find(item => item.title === 'Comments'))
                this._navigation[3].items.splice(index, 0, {
                    title: 'Messages',
                    url: '/client-messages',
                });
            };

            if (this.enableGroupChat) {
                const index = this._navigation[3].items.indexOf(this.navigation[3].items.find(item => item.title === 'Comments'))
                this._navigation[3].items.splice(index, 0, {
                    title: 'Reported messages',
                    url: '/reported-messages',
                });
            }

            this._navigation.push({
                title: 'Website',
                color: '#C2F1FF',
                icon: 'assets/icons/ic_website.svg',
                iconHome: 'assets/icons/ic_website_home.svg',
                opened: false,
                items: [
                    {
                        title: 'Overview',
                        url: '/landing-page',
                    },
                    {
                        title: 'Customize layout',
                        url: '/landing-page/app-features',
                    },
                ]
            });

            const index = this._navigation[3].items.indexOf(this.navigation[3].items.find(item => item.title === 'Subscriptions'))
            this._navigation[3].items.splice(index, 0, {
                title: 'Tailor requests',
                url: '/plan-requests',
            });

            if (this.enableReferrals) {
                this._navigation[5].items.push({
                    title: 'Referral program',
                    url: '/referral-code',
                });
            }

            if (this.enableChallenges) {
                this._navigation[2].items.push({
                    title: 'Challenges',
                    url: '/challenge',
                });
            }

            const index2 = this._navigation[6].items.indexOf(this.navigation[6].items.find(item => item.title === 'Customize text'))
            this._navigation[6].items.splice(index2, 0, {
                title: 'Customize app',
                url: '/mobile-config',
            });

            if (this.enableCoaching) {
                const index = this._navigation.indexOf(this.navigation.find(item => item.title === 'App features'))
                this._navigation.splice(index, 0, {
                    title: 'Coaching',
                    color: '#C1F1C6',
                    icon: 'assets/icons/ic_coaching.svg',
                    iconHome: 'assets/icons/ic_coaching_home.svg',
                    opened: false,
                    items: [
                        {
                            title: 'Coaching lessons',
                            url: '/lesson',
                        },
                        {
                            title: 'Coaching categories',
                            url: '/course',
                        },
                        {
                            title: 'Achievements',
                            url: '/lesson-achievement',
                        },
                        {
                            subtitle: 'SETUP',
                            opened: false,
                            hideOnHome: true,
                            title: 'Coaching setup',
                            items: [
                                {
                                    title: 'Lesson types',
                                    homeTitle: 'Lesson types',
                                    url: '/lesson-type',
                                },
                                {
                                    title: 'Lesson goals',
                                    homeTitle: 'Lesson goals',
                                    url: '/lesson-goal',
                                },
                                {
                                    title: 'Category groups',
                                    homeTitle: 'Category groups',
                                    url: '/course-category',
                                },
                            ],
                        },
                    ],
                });
            }
        }
        
        this._navigation.push();
    }

    get isRoot() {
        return this._isRoot;
    }

    public async getConfig() {
        const jsonFile = `assets/config/config.${environment.name}.json`;
        return new Promise<FitAppConfig>((resolve, reject) => {
            if (FitConfigProviderService.settings) {
                resolve(FitConfigProviderService.settings);
            } else {
                fetch(jsonFile)
                    .then((res) => {
                        if (res.headers.has('x-white-label-application-pin')) {
                            this.pinApp = res.headers.get(
                                'x-white-label-application-pin'
                            );
                        }
                        return res.json();
                    })
                    .then((response: FitAppConfig) => {
                        FitConfigProviderService.settings = response as FitAppConfig;
                        resolve(response);
                    })
                    .catch((response: any) => {
                        reject(
                            `Could not load file '${jsonFile}': ${JSON.stringify(
                                response
                            )}`
                        );
                    });
            }
        });
    }

    public getCountryList() {
        return new Promise<Country[]>((resolve, reject) => {
            fetch('assets/config/countries.json')
                .then(res => res.json())
                .then(countries => resolve(countries))
                .catch(() => reject('Error loading list of countries'))
        });
    }

    private getMainCmsNavigation(): SidebarNavigation[] {
        return [
            {
                title: 'My clients',
                color: '#E4D6F6',
                icon: 'assets/icons/ic_my_clients.svg',
                iconHome: 'assets/icons/ic_my_clients_home.svg',
                opened: false,
                items: [
                    {
                        title: 'All users',
                        url: '/user/user',
                    },
                ],
            },
            {
                title: 'App analytics',
                color: '#FFDBD7',
                icon: 'assets/icons/ic_app_statistics.svg',
                iconHome: 'assets/icons/ic_app_statistics_home.svg',
                opened: false,
                items: [
                    {
                        title: 'Statistics',
                        url: '/statistics',
                    },
                    {
                        title: 'Reports',
                        url: '/statistics/reports',
                    },
                    {
                        title: 'Content analytics',
                        url: '/business-intelligence',
                    },
                ],
            },
            {
                title: 'Applications',
                color: '#FFCCBC',
                icon: 'assets/icons/ic_library.svg',
                iconHome: 'assets/icons/ic_library_home.svg',
                opened: false,
                items: [
                    {
                        title: 'Applications list',
                        url: '/applications',
                    },
                    {
                        title: 'Applications insight',
                        url: '/app-insight',
                    },
                    {
                        title: 'User search',
                        url: '/user-search',
                    },
                    {
                        title: 'Recordings',
                        url: '/recording',
                    },
                    {
                        title: 'Coach packages',
                        url: '/coach-packages',
                    },
                ],
            },
            {
                title: 'Library',
                color: '#F8BBD0',
                icon: 'assets/icons/ic_library.svg',
                iconHome: 'assets/icons/ic_library_home.svg',
                opened: false,
                items: [
                    {
                        title: 'Exercises',
                        url: '/exercise-library',
                    },
                ],
            }
        ];
    }

    private getAdminNavigation(): SidebarNavigation[] {
        return [
            {
                title: 'Workout',
                color: '#CFDAF6',
                icon: 'assets/icons/ic_workout.svg',
                iconHome: 'assets/icons/ic_workout_home.svg',
                opened: false,
                items: [
                    {
                        title: 'Exercises',
                        url: '/exercise',
                    },
                    {
                        title: 'Workouts',
                        url: '/training',
                    },
                    {
                        title: 'Programs',
                        url: '/program',
                    },
                    {
                        title: 'Tailored programs',
                        url: '/tailor-made',
                    },
                    {
                        subtitle: 'SETUP',
                        title: 'Workout setup',
                        opened: false,
                        hideOnHome: true,
                        items: [
                            {
                                title: 'Workout types',
                                url: '/training-type',
                            },
                            {
                                title: 'Muscle groups',
                                url: '/muscle-group',
                            },
                            {
                                title: 'Goals',
                                url: '/goal',
                            },
                            {
                                title: 'Level',
                                url: '/level',
                            },
                            {
                                title: 'Environment',
                                url: '/environment',
                            },
                            
                            {
                                title: 'Equipment',
                                url: '/equipments',
                            },
                        ],
                    },
                ],
            },
            {
                title: 'Nutrition',
                color: '#F2E7D5',
                icon: 'assets/icons/ic_nutrition.svg',
                iconHome: 'assets/icons/ic_nutrition_home.svg',
                opened: false,
                items: [
                    {
                        title: 'Recipes',
                        url: '/meal',
                    },
                    {
                        title: 'Recipe books',
                        url: '/nutrition-program',
                    },
                    {
                        title: 'Tailored books',
                        url: '/nutrition-program-tailor-made',
                    },
                    {
                        subtitle: 'SETUP',
                        opened: false,
                        hideOnHome: true,
                        title: 'Nutrition setup',
                        items: [
                            {
                                title: 'Meal groups',
                                url: '/meal-group',
                            },
                            {
                                title: 'Diets',
                                url: '/diet',
                            },
                            {
                                title: 'Food preferences',
                                url: '/food-preference',
                            },
                            {
                                title: 'Allergens',
                                url: '/allergen',
                            },
                        ],
                    },
                ],
            },
            {
                title: 'App features',
                color: '#C3D5FF',
                icon: 'assets/icons/ic_app_features.svg',
                iconHome: 'assets/icons/ic_app_features_home.svg',
                opened: false,
                items: [
                    {
                        subtitle: 'QUESTIONNAIRES',
                        opened: false,
                        hideOnHome: true,
                        title: 'Questionnaires',
                        items: [
                            {
                                title: 'Created by you',
                                homeTitle: 'Questionnaires',
                                url: '/questionnaire',
                            },
                            {
                                title: 'Completed by clients',
                                homeTitle: 'Questionnaire answers',
                                url: '/finished-questionnaires',
                            },
                        ],
                    },
                    {
                        title: 'Live events',
                        url: '/live-events',
                    },
                    {
                        title: 'Blog',
                        url: '/blog',
                    },
                    {
                        title: 'Feed',
                        url: '/feed',
                    },
                    {
                        title: 'Achievements',
                        url: '/achievements',
                    },
                ],
            },
            {
                title: 'My clients',
                color: '#E4D6F6',
                icon: 'assets/icons/ic_my_clients.svg',
                iconHome: 'assets/icons/ic_my_clients_home.svg',
                opened: false,
                items: [
                    {
                        title: 'All users',
                        url: '/user/user',
                    },
                    {
                        title: 'Comments',
                        url: '/review/comment',
                    },
                    /*
                    {
                         title: 'Users status',
                         url: '/user-status',
                     },
                     */
                    /*
                    {
                        title: 'Report Page',
                        url: '/report-page',
                    },
                    */
                    {
                        title: 'Subscriptions',
                        url: '/subscription',
                    },
                    {
                        title: 'Payments',
                        url: '/payment',
                    },
                    /*
                    {
                        title: 'Charges Report',
                        url: '/charges',
                    },
                    */
                ],
            },
            {
                title: 'App analytics',
                color: '#FFDBD7',
                icon: 'assets/icons/ic_app_statistics.svg',
                iconHome: 'assets/icons/ic_app_statistics_home.svg',
                opened: false,
                items: [
                    /*
                    {
                        title: 'Statistics',
                        url: '/statistics',
                    },
                    {
                        title: 'Reports',
                        url: '/statistics/reports',
                    },
                    {
                        title: 'Content analytics',
                        url: '/business-intelligence',
                    },
                    */
                ],
            },
            {
                title: 'App promotion',
                color: '#CFE4DD',
                icon: 'assets/icons/ic_app_promotion.svg',
                iconHome: 'assets/icons/ic_app_promotion_home.svg',
                opened: false,
                items: [
                    {
                        title: 'Products',
                        url: '/insight',
                    },
                    {
                        title: 'Affiliate partners',
                        url: '/affiliate-partner',
                    },
                    {
                        title: 'Promo codes',
                        url: '/promo-codes',
                    },
                ],
            },
            {
                title: 'App setup',
                color: '#D5DCE9',
                //subtitle: 'Create workouts, write blogs and track your app\'s performance.',
                icon: 'assets/icons/ic_app_setup.svg',
                iconHome: 'assets/icons/ic_app_setup_home.svg',
                opened: false,
                items: [
                    {
                        title: 'Customize text',
                        url: '/translate',
                    },
                ]
            },
        ];
    }

    private getCoachNavigation(): SidebarNavigation[] {
        return [
            {
                title: 'My clients',
                color: '#E4D6F6',
                icon: 'assets/icons/ic_my_clients.svg',
                iconHome: 'assets/icons/ic_my_clients_home.svg',
                opened: false,
                items: [
                    {
                        title: 'All users',
                        url: '/user/user',
                    }
                ],
            },
            {
                title: 'Workout',
                color: '#CFDAF6',
                icon: 'assets/icons/ic_workout.svg',
                iconHome: 'assets/icons/ic_workout_home.svg',
                opened: false,
                items: [
                    {
                        title: 'Exercises',
                        url: '/exercise',
                    },
                    {
                        title: 'Workouts',
                        url: '/training',
                    },
                    {
                        title: 'Programs',
                        url: '/program',
                    }
                ]
            }
        ];
    }

    private _navigation: SidebarNavigation[] = [];
    private _pinApp: string;
    private _isRoot = false;
    private _isOwner = false;
    private _isAdministrator = false;
    private _isCoach = false;
    private _enableUserHealthTracking = false;
    private _enableClientMessaging = false;
    private _enableGroupChat = false;
    private _enableScheduler = false;
    private _enableReferrals= false;
    private _enableChallenges = false;
    private _enableCoaching = false;
    private _enableTeams = false;
    private _enableGiftCards = false;
    private _enableAppTryout = false;
}
